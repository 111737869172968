.headerContainer {
  @apply w-full flex justify-between items-center mt-[12px] md:mt-[29px] gap-0 rounded-t-[14px]  max-h-[120px] p-[12px] pb-[6px] pt-[6px];
  background: rgb(253, 154, 79);
  background: linear-gradient(0deg, rgba(253, 154, 79, 1) 10%, rgba(254, 128, 74, 1) 99%);
  .titleContainer {
    @apply flex flex-col sm:leading-[33px] leading-[25px];
    .title {
      @apply font-bold;
    }
    .desc {
      @apply text-[#1D2027] font-semiBold;
    }
  }

  .filterContainer {
    @apply sm:min-w-[190px] sm:h-[34px] min-w-[155px] h-[34px] rounded-[8px] bg-[#121419] 
    flex justify-between items-center px-3 w-fit gap-1 self-end mb-1;

    .filterItem {
      @apply md:text-[14px] text-[12px] font-normal cursor-pointer text-[#FFFFFF];
    }
  }
}
