.contentContainer {
  @apply lg:px-5 overflow-visible mt-[3%] lg:w-[980px] xl:h-[380px] h-auto w-full pb-[5%];
  .modalTitle {
    @apply text-center text-[22px] font-bold uppercase text-[#E8C944] md:text-[32px] lg:text-[40px] flex-col flex leading-[40px];
  }
  .modalSubTitle {
    @apply pr-[0px] pl-[0px] text-center text-[12px] font-bold text-[#FFFFFF] md:pr-[100px] md:pl-[100px] md:text-[22px] lg:text-[26px];
  }
  .bodyContainer {
    @apply w-full flex lg:flex-row flex-col lg:justify-start justify-center gap-5;
    .imageContainer {
      @apply w-[290px] aspect-square relative;
    }
    .descriptionContainer {
      @apply flex flex-col w-full lg:w-[calc(100%-300px)];
      .title {
        @apply font-bold text-[22px] text-white lg:mt-[38px] mt-[8px];
      }
      .descContainer {
        @apply overflow-y-auto h-[150px] z-10;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #262930;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #4c4f50;
        }

        &::-webkit-scrollbar {
          @apply w-[6px];
        }
        .desc {
          @apply text-[17px] text-[#AAAAAA] mx-1 break-words;
        }
      }
    }
 
  }
}
.shadow {
  filter: blur(100px);
  @apply absolute top-0 right-0 left-0 mx-auto h-[250px] w-[500px] -translate-y-[150px] rounded-[100%] bg-[#9FCB59] opacity-[46%];
}
.shadowContainer {
  @apply absolute right-0 left-0 top-0 z-0 h-[100%] w-[100%] overflow-hidden rounded-t-[0.25rem];
}
.isDoneContainer {
  @apply flex justify-center items-center gap-2;
  .title {
    @apply font-bold text-[15px] text-[#717275] leading-[5px];
  }
  .date {
    @apply font-bold text-[15px] text-[#00D27E];
  }
  .tickContainer {
    @apply w-[20px] h-[20px] bg-[#00D27E] rounded-full flex justify-center items-center p-[2px];
  }
}
