.circle {
  @apply bg-[#848888] opacity-[.8] w-[64px] h-[64px] rounded-[64px] flex justify-center items-center absolute top-[50%] left-[50%]  inset-0 z-[1] cursor-pointer;
  transform: translate(-50%, -50%);
  .triangle {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-left: 23px solid white;
    border-bottom: 12px solid transparent;
  }
}
