.container {
  @apply 2xl:w-[526px] xl:w-[406px] sm:w-[360px] w-[340px]
  rounded-[14px];
  // border-[2px]  md:border-[3px]
  .bodyContainer {
    @apply overflow-y-auto pb-3 flex flex-col gap-2 mt-[8px];
    // @apply overflow-y-auto sm:h-[80%] h-[75%] pb-3 mt-7 flex flex-col gap-4 sm:max-h-[750px];
    // sm:h-[82%] h-[65%]

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #262930;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #4c4f50;
    }

    &::-webkit-scrollbar {
      @apply w-[6px];
    }
    .bodyItemContainer {
      @apply md:flex h-auto flex-col flex lg:flex-row;
      .image {
        @apply relative w-[117px] h-[117px] rounded-md;
      }
      .informationContainer {
        @apply leading-[35px] flex flex-col px-1 xl:px-3 lg:w-[calc(100%-117px)] w-full;
        .title {
          @apply md:text-[16px] text-[14px] text-white font-bold md:leading-[24px] 
          leading-[16px] mb-2 md:mt-2 mt-4;
        }
        .desc {
          @apply text-[12px] md:text-[14px] text-[#717275] leading-[20px] 
          text-ellipsis overflow-hidden text-wrap md:line-clamp-2 sm:line-clamp-3 line-clamp-2;
          //
        }
      }
    }
  }

  .separatorContainer {
    @apply flex justify-between items-center w-full lg:pt-3 lg:pb-3 pt-1 pb-1;
  }
}

.dataContainer {
  @apply sm:text-[14px] text-[12px] text-[#717275] flex px-1;
  .tickContainer {
    @apply w-[20px] h-[20px] bg-[#00D27E] rounded-full flex justify-center items-center p-[2px] ml-2;
  }
}

.cardItem {
  @apply cursor-pointer bg-[#1D2027] rounded-[10px] p-2 pb-0;
}
