.list {
  max-height: calc(100vh - 200px);
  @apply 2xl:w-[1100px] xl:w-[1000px]  2xl:px-[76px] xl:px-[30px] lg:px-[30px] overflow-y-auto mt-[30px] lg:pr-0 pr-2 h-[100%];
    //  : calc(100vh - 44px);
  // lg:h-[770px] md:h-[660px] sm:h-[700px] max-h-[650px]
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    border-radius: 10px;

    background-color: #444549;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: #d2d5dd;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
}

// form.module.scss



.title {
  @apply text-white text-center md:text-[36px] text-[32px];
}

.label {
  @apply text-white mb-[10px];
}

.field {
  @apply w-full h-[44px] bg-[#1D2027] rounded-[8px] text-white placeholder:text-[#727272] placeholder:text-[12px] md:placeholder:text-[16px] placeholder:px-0 px-5;
}

.textarea {
  @apply w-full h-[147px] max-h-[147px] bg-[#1D2027] rounded-[8px] text-white placeholder:text-[#727272] placeholder:text-[12px] md:placeholder:text-[16px] placeholder:px-0 px-5 py-2 resize-none;
}

.uploadButton {
  @apply bg-[#1D2027] hover:bg-[#1D2027] h-[50px] border-0 rounded-[8px];
}

.submitButton {
  @apply w-[150px] h-[45px] sm:w-[280px] sm:h-[54px] rounded-[8px] mx-auto mb-2 sm:mt-[30px] md:mt-[20px];
}
.submitButtonEnabled {
  background-color: #00F4FF !important;
}
.submitButtonDisabled {
  @apply bg-[#707070];
  background-color: #707070 !important;
}

.typography {
  @apply md:text-[18px] text-[14px];
}

.typographyLarge {
  @apply text-[#121419] sm:text-[24px] text-[18px] uppercase;
}

.mdText {
  @apply md:text-[16px] text-[12px];
}


.customInputMask {
  background-color: #1D2027 !important;
  border: 0 !important;
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;

  &:active,
  &:focus,
  &:hover {
    background-color: #1D2027 !important;
    border: 0 !important;
    border-color: transparent !important;

    outline: none !important;
    box-shadow: none !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px #1D2027  !important;
    box-shadow: 0 0 0px 1000px #1D2027 inset !important;
    -webkit-text-fill-color: white !important;
    border: 0 !important;
    outline: none !important;
    border-color: transparent !important;

  }
}

.titleContainer {
  @apply md:mb-[30px] sm:mb-[62px] mb-[30px] 2xl:px-[76px] xl:px-[30px] lg:px-[30px];
  .title {
    @apply text-white text-center md:text-[36px] text-[32px];
  }
  .desc {
    @apply text-white md:text-center text-start md:text-[16px] text-[14px];
  }
}
