.progressContainer {
  @apply relative z-10 mt-8;
}

.headerContainer {
  @apply flex justify-center w-[98%];
  // absolute top-[-40px]
}

.headerText {
  @apply lg:text-[27px] text-[20px] font-bold text-white flex self-center mt-5;
}

.submittedTextContainer {
  @apply flex justify-start lg:justify-end;
}

.submittedText {
  @apply font-bold lg:text-[16px] text-[13px] flex text-white mt-[50px] lg:mt-[40px];
}

.submittedBy {
  @apply text-[#AAAAAA] mx-2;
}

.listContainer {
  @apply h-[calc(100vh-200px)] md:max-h-[600px] lg:max-h-[600px] xl:max-h-[500px] 
  2xl:max-h-[600px] overflow-y-auto relative z-10 mt-8 flex flex-col gap-[22px] pr-[5px];
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    border-radius: 10px;

    background-color: #707070;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: #ffffff;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
}

.listItem {
  @apply w-full flex flex-col items-center;
}

.greenBar {
  @apply lg:w-[5px] w-[3px] lg:h-[108px] h-[80px] rounded-r-full self-center 2xl:mr-10 xl:mr-10 lg:mr-6;
}

.imageContainer {
  @apply lg:w-[144px] lg:h-[145px] w-[90px] h-[90px] relative;
}

.overlay {
  @apply bg-black w-full h-full absolute z-10 bg-opacity-50 backdrop-blur-[8px] rounded-[6px] top-0 left-0;
}

.textContainer {
  @apply w-[75%] h-full lg:h-fit lg:mt-4 rounded-[6px] flex flex-col lg:flex lg:flex-row justify-end lg:justify-start lg:pb-0 pb-2 border;
}

.ideaText {
  @apply lg:text-[22px] text-[16px] font-bold text-white uppercase;
}

.descriptionText {
  @apply text-[14px] lg:text-[17px] text-[#AAAAAA] mt-1;
}

// .buttonContainer {
//   @apply w-full flex justify-end mb-3 lg:mb-0;
// }

.button {
  @apply h-[20px] flex gap-2 text-end;
}
