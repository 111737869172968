.container {
  @apply flex flex-wrap gap-4 xl:justify-center lg:justify-start justify-center;
}
/* roadMapItem.module.scss */
.scrollableContainer {
  height: 600px; /* Adjust height as needed */
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px; /* Only apply width for vertical scrollbar */
    height: 0px; /* Hide horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #262930;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #4c4f50;
  }
}
